export interface IMenuItem {
  id?: string;
  src?:any;
  icon?: string;
  label: string;
  to: string;
  newWindow?: boolean;
  subs?: IMenuItem[];
}

const data: IMenuItem[] = [
  {
    icon: 'fa fa-tachometer',
    label: 'Dashboard',
    to: '/dashboard',
    src: '../../../assets/images/dashboard-icon.png',
  },
  {
    icon: 'fa fa-calculator',
    label: 'Cartridges',
    to: '/cartridge',
    src: '../../../assets/images/Cat.png',
  },
  {
    icon: 'fa fa-calculator',
    label: 'eTrapps',
    to: '/etrapp',
    src:'../../../assets/images/eTrapp_device_icon.png',
  },
  {
    icon: 'fa fa-calculator',
    label: 'eTrapps Management',
    to: '/etrapp-management',
    src:'../../../assets/images/eTrapp_device_icon.png',
  },
  {
    icon: 'fa fa-book',
    label: 'Care Center',
    to: '/customer',
    src: '../../../assets/images/customer-service.png',

  },
  {
    icon: 'fa fa-hospital-o',
    label: 'Rewards',
    to: '/rewards',
    src: '../../../assets/images/Financial-icon.png',
    subs: [
      {
        icon: "simple-icon-arrow-right-circle",
        label: "Redeem",
        to: "/rewards/redeem",
  
      },
      {
        icon: "simple-icon-arrow-left-circle",
        label: "Invoice",
        to: "/rewards/invoice", 
      }
    ]

  },
  {
    icon: 'fa fa-tasks',
    label: 'Customer List',
    to: '/consumer',
    src: '../../../assets/images/Layer35.png',

  },
  {
    icon: 'fa fa-cog',
    label: 'Technician',
    to: '/pros',
    src: '../../../assets/images/Mens-icon.png',

  },
  {
    icon: 'simple-icon-control-play',
    label: 'Video Tutorials',
    to: '/tutorial',
    src: '',

  },
  {
    icon: 'fa fa-cog',
    label: 'Settings',
    to: '/settings',
    src: '../../../assets/images/Gear-icon.png',

  },
];
export default data;
