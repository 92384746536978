<nav class="navbar fixed-top">
  <div class="d-flex navbar-left">
    <a (click)="
              menuButtonClick(
                $event,
                sidebar.menuClickCount,
                sidebar.containerClassnames
              );
              (false)
            " href="javascript:;" class="menu-button d-none d-md-block">
      <svg class="main" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 17">
        <rect x="0.48" y="0.5" width="7" height="1" />
        <rect x="0.48" y="7.5" width="7" height="1" />
        <rect x="0.48" y="15.5" width="7" height="1" />
      </svg>
      {{ " " }}
      <svg class="sub" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 17">
        <rect x="1.56" y="0.5" width="16" height="1" />
        <rect x="1.56" y="7.5" width="16" height="1" />
        <rect x="1.56" y="15.5" width="16" height="1" />
      </svg>
    </a>
    <a (click)="mobileMenuButtonClick($event, sidebar.containerClassnames)" href="javascript:;"
      class="menu-button-mobile d-xs-block d-sm-block d-md-none">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 17">
        <rect x="0.5" y="0.5" width="25" height="1" />
        <rect x="0.5" y="7.5" width="25" height="1" />
        <rect x="0.5" y="15.5" width="25" height="1" />
      </svg>
    </a>
    <span class="text-small text-muted mr-2">Your wholesaler is</span>
    <span class=" text-primary wholesaler c-pointer" (click)="editWholesaler(this.global.name)">{{this.global.name ===
      undefined ? '':
      this.global.name}} </span>
    <i class="simple-icon-note mx-2 text-primary c-pointer" (click)="editWholesaler(this.global.name)"></i>
  </div>
  <div class="text-center">
    <a class="navbar-logo" [routerLink]="['/dashboard']">
      <span class="logo d-none d-xs-block align-items-center" [ngStyle]="{'background-image': EntityType === 1 ? 'url(../../../../assets/img/eTrapp_pro_Blue.png)' : 'url(../../../../assets/img/eTrapp_enterprise_Blue.png)', 'background-repeat': 'no-repeat'}"></span>
      <span class="logo-mobile d-block d-xs-none align-items-center" [ngStyle]="{'background-image': EntityType === 1 ? 'url(../../../../assets/img/eTrapp_pro_Blue.png)' : 'url(../../../../assets/img/eTrapp_enterprise_Blue.png)', 'background-repeat': 'no-repeat'}"></span>
    </a>
    
  
    <ol class="breadcrumb hider" style="font-size: 8px">
      <li class="breadcrumb-item ng-star-inserted">
        <a ng-reflect-router-link="/dashboard"><br /></a>
      </li>
    </ol>
  </div>
  <div class="navbar-right " style="padding: 0px 50px">
    <div class="header-icons d-inline-block align-text-center ">
      <div class="d-none d-md-inline-block align-text-center ml-5">
        <div class="" >
            <div class="user d-inline-block" dropdown>
          <button class="btn btn-empty" type="button" dropdownToggle style="padding: 0px 5px">
            <div class="d-flex">
            <h3 class="text-primary mt-0 right-align mr-3" >
              {{proObj.proName}}
            </h3>
           <i class="fa fa-caret-down  text-primary" style="margin-top:5px"></i>
          </div>

          </button>
          <div class="dropdown-menu  mt-4 px-4" *dropdownMenu role="menu" style="min-width: 19rem;">
            <h2 class="text-primary mt-1 text-center" >
              {{proObj.proName}}
            </h2>
            <div class="row">
              <div class="col-4">
                <img alt="Profile Picture" src="/assets/img/store.png" style="width: 60px;"/>
              </div>
              <div class="col-8" style="font-size: 10px;">
                <span> <b>{{proObj.address}}</b></span><br>
                <span> <b>{{proObj.phoneNumber}}</b></span><br>
                <span> <b>{{proObj.email}}</b></span><br><br>
                <button type="submit" class="btn btn-primary btn-xs text-align-center mt-2 pull-right" 
                (click)="logoutuser()">
                  Sign out
                </button>
              </div>
            </div>
            <div class="form">
              <h3 class="mt-2">Inventory</h3>
              <h3>eDelta products:</h3>
            </div>
            <form [formGroup]="InventoryForm" novalidate (ngSubmit)="submitInventory(InventoryForm.value)">
                <div class="form-row">
                  <div class="form-grop col-12 mt-1 ">
                    <label>Catridges
                      <span class="text-danger">*</span>
                    </label>
                    <input type="text" class="form-control" placeholder="" formControlName="Catridges" (click)="stopDropdownClick($event)"/>
                  </div> 
                  <div class="form-grop col-12 mt-1 ">
                    <label>Etrapps
                      <span class="text-danger">*</span>
                    </label>
                    <input type="text" class="form-control" placeholder="" formControlName="Etrapps" (click)="stopDropdownClick($event)"/>
                  </div>
                </div>
              
                <button type="submit" class="btn btn-primary btn-xs text-align-center mt-2 pull-right" [disabled]="!InventoryForm.valid">
                  Save
                </button>
             
            </form>
          </div>
        </div>
         
          <ol class="breadcrumb hider " style="font-size: 8px; padding: 0px;">
            <li class="breadcrumb-item ng-star-inserted ">
              <b class="">{{proObj.address}}</b>
            </li>
            <li class="breadcrumb-item ng-star-inserted">
              <a ng-reflect-router-link="/app">System status &nbsp;&nbsp;<span class="fa fa-wifi"
                  style="color: green"></span></a>
            </li>
          </ol>
        </div>
      </div>
    </div>
    <!-- <div class="user d-inline-block " dropdown>
      <button class="btn btn-empty pl-0 pb-4 mb-2 mr-5 " type="button" dropdownToggle>
        <img alt="Profile Picture" src="/assets/img/store.png" />
        <i class="fa fa-caret-down mb-1 text-primary"></i>
      </button>
      <div class="dropdown-menu dropdown-menu-right mt-0" *dropdownMenu role="menu">
        <li class="divider dropdown-divider"></li>
        <a class="dropdown-item c-pointer" (click)="logoutuser()">Sign out</a>
      </div>
    </div> -->
  </div>
</nav>
<ng-template #openOrgSetting>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Organization Settings</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeOrgSettingModal()">
      <span aria-hidden=" true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <perfect-scrollbar class="scroll dashboard-list-with-thumbs"
      [config]="{ suppressScrollX: true, wheelPropagation: false }">
      <div class="form-row">
        <div class="form-group col-md-6">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Theme Configuration</h5>
              <app-color-switcher></app-color-switcher>
            </div>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
  <div class="modal-footer">
    <button class="btn btn-outline-danger mb-1" (click)="closeOrgSettingModal()">
      Cancel
    </button>
  </div>
</ng-template>

<ng-template #addWholesaler>
  <div class="child-modal">
    <div class="modal-header">
      <h4 class="modal-title pull-left">Wholesaler</h4>
      <button *ngIf="this.topNavVar.wholesalerId !== null" type="button" class="close pull-right" aria-label="Close"
        (click)="closeAddWholesalerModal()">
        <span aria-hidden=" true">&times;</span>
      </button>
    </div>

    <form [formGroup]="addWholesalerForm" novalidate (ngSubmit)="submitAddWholesaler(addWholesalerForm.value)">
      <div class="modal-body">
        <div class="info">
          <p class="mb-0 font-weight-bold">{{this.proObj.wholesalerName}}
          </p>
          <p class="mb-0 font-weight-bold">{{this.proObj.address}}
          </p>
          <p class="mb-0 font-weight-bold text-primary">{{this.proObj.contactPhone}}
          </p>
          <p class="font-weight-bold"><span>Contact Person: </span> <span>{{this.proObj.contactFirstname + " "+this.proObj.contactLastname}} </span></p>  
        </div>
        <div class="row">
          <div class="form-grop col-12 ">
            <label>Select Wholesaler</label>
            <select class="form-control" formControlName="name">
              <option selected disabled hidden value="null">Select Wholesaler</option>
              <option value="" *ngIf="topNavVar.wholesalers.length==0" disabled>No Wholesaler Found
              </option>
              <option *ngFor="let wholesaler of topNavVar.wholesalers" [ngValue]="wholesaler.name">
                {{wholesaler.name}}</option>
            </select>
          </div>
          <div *ngIf="
              addWholesalerForm.controls.name.hasError('required') && 
              addWholesalerForm.controls.name.touched" class="invalid-tooltip">
            Wholesaler is required!
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-outline-primary" type="submit" [disabled]="!addWholesalerForm.valid">
          {{ addWholesalerForm.value.wholesalerId === 0 ? "Save" : "Update" }}
        </button>
        <button *ngIf="this.topNavVar.wholesalerId !== null" class="btn btn-outline-danger" type="button"
          (click)="closeAddWholesalerModal()">
          Cancel
        </button>
      </div>
    </form>
  </div>
</ng-template>
<ng-template #instructions>
  <div class="child-modal">
    <div class="modal-header">
      <h4 class="modal-title pull-left">
        <!-- <i class="fa fa-file-text-o fa-lg font-weight-bold  mr-1" aria-hidden="true"> </i> -->
        <span>
          <img src="assets/img/eTrappLogoDesignFINAL.svg" class=" ml-1 mb-1 w-15" alt="eTrapp">
          <span>
            Installation Guidelines
          </span>
        </span>
      </h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="closeInstructionsModal()">
        <span aria-hidden=" true">&times;</span>
      </button>
    </div>

    <div class="modal-body guideline">
      <perfect-scrollbar [config]="{ suppressScrollX: true, wheelPropagation: true }">
        <div class="row">
          <div class="form-grop col-12 mx-auto">
            <p>What you will need before you begin</p>
            <ul>
              <li>Basic plumbing tools/hardware.</li>
              <li>Some installations will require 1’’ x 3/4’’ PVC reducing bushings.</li>
              <li>110 V outlet within 6’ of the installation.</li>
            </ul>
            <p>Installation of the eTrapp is broken down into 3 categories.</p>
            <ul>
              <li>Plumbing</li>
              <li>Wiring</li>
              <li>Configuration</li>
            </ul>
            <h2>Plumbing</h2>
            <p>The eTrapp unit is designed to fully replace standard plumbing p-traps.</p>
            <h4>Step 1:</h4>
            <p> Locate the air conditioner’s condensate outlet and determine the thread size. Most residential units
              will utilize ¾’’ fittings. If the installation is being performed on existing construction, plumbing
              will need to be disconnected and the p-trap will need to be cut out. eTrapp is threaded in 1’’ female
              NPT threads. For residential applications a 1’’ x 3/4’’ reducing bushing will need to be fitted.</p>
            <p>Plumb the eTrapp unit into the condensate line closest to the condensate outlet. Take care to ensure
              the unit is mounted level and the condensate lines downstream have sufficient fall to allow water to
              flow. The top and bottom of the eTrapp are designed to be removable for easy maintenance. Before gluing
              any fittings, ensure that both the threaded bottom and the twist-lock cartridge (top) have enough
              clearance to be removed fully. The light on the eTrapp canister provides user feedback for worry-free
              cartridge replacement. This light should face outwards to easily be viewed when replacing cartridges.
              See Figure 1 (below).</p>
            <h4>Optional Step:</h4>
            <p>Most residential AC units feature two condensate outlet ports. Often the second port is unused and
              terminated with a plug. The terminated port sits slightly higher than the primary condensate outlet and
              only experiences condensate flow when the primary port becomes clogged. Installers may choose to plumb a
              down pipe with a U-bend to this secondary condensate drain (Figure 1). This Optional step will reroute
              condensate directly to the external overflow pan in the unlikely event the primary line becomes clogged.
              This step will allow eTrapp’s pan sensor to immediately detect an overflow condition and respond. If
              this step is not performed, eTrapp’s Pan sensor will still function, however, the internal drain pan
              will have to fill and overflow into the external pan before detection. </p>
            <div class="row">
              <div class="col-12 text-center">
                <img alt="Profile Picture" src="/assets/img/a.jpg" />
                <p> <b>Figure 1:</b> Proper plumbing installation of eTrapp unit</p>
              </div>
            </div>
            <h2>Wiring</h2>
            <h4>Step 2:</h4>
            <p>Installing all electrical components. Connect the provided power block to a 110 V outlet. The USB type C
              power cable should be connected to the IoT hub’s port marked with the power symbol. Next, connect the
              cable marked eTrapp to the female USB-C port below the indicator light on eTrapp. At this time, both the
              indicator light on the eTrapp and the IoT hub should be illuminated. Next locate the provided pan
              sensor. The pan sensor is shipped already installed on a magnetic mounting bracket. If your overflow pan
              is made from a non-ferrous material, you will need to remove the magnet and replace it with the
              double-sided adhesive tape included in the small parts bag. Install the pan sensor onto the side of the
              condensate overflow pan. The sensor’s longer leg should touch the floor of the drain pan and the short
              leg should not be in contact with any surface. See Figure 2. </p>
            <div class="row">
              <div class="col-12 text-center mb-3">
                <img alt="Profile Picture" src="/assets/img/figure2.png" />
              </div>
            </div>
            <p>It is now time to pick a mounting location for the IoT hub. Choose a smooth surface within reach of all
              attached cables and mount the IoT hub using the provided adhesive-backed Velcro. The indicator light and
              text should face outward. While many installers mount directly to the side of the AC unit, signal
              strength will be improved if a nonmetallic mounting surface is available. See Figure 3. </p>
            <div class="row">
              <div class="col-12 text-center mb-3">
                <img alt="Profile Picture" src="/assets/img/figure3.png" />
              </div>
            </div>
            <h4>Optional Step:</h4>
            <p>When the AC cut off cable is installed, eTrapp can shut down the AC unit to prevent property damage
              when a flood condition is detected. This feature is optional, but HIGHLY recommended. If you choose not
              to utilize this feature, simply use a provided cable tie to bundle and secure the AC cut-off cable. To
              install this feature, first shut down power to the AC unit and locate where the thermostat wires tie
              into the air handler. Remove the wire nut or electrical connector attaching the 24V power supply from
              the AC to the thermostat power (red). Connect the red cable from the AC cutoff lead to the power
              supply wire and the black lead to the thermostat power wire. This will connect eTrapp’s IoT hub in
              series with the thermostat. Additional wire connectors are provided in the small parts kit. Finally,
              connect the green wire to the thermostat common (white). See Figure 4. </p>
            <div class="row">
              <div class="col-12 text-center mb-3">
                <img alt="Profile Picture" src="/assets/img/figure4.png" />
              </div>
            </div>
            <h2>Configuration </h2>
            <p>All eTrapp hardware is now installed, and it is time to configure the system. Using any smartphone,
              download the “eTrapp” application by scanning the QR Code below or searching “eTrapp” in the app store.
            </p>
            <div class="row">
              <div class="col-6 text-center">
                <p class="mb-0">Google Play Store</p>
                <img alt="Profile Picture" src="/assets/img/google.png" />
              </div>
              <div class="col-6 text-center">
                <p class="mb-0">Apple App Store</p>
                <img alt="Profile Picture" src="/assets/img/apple.png" />
              </div>
            </div>
            <p>Once the application is installed, log in or create an account to begin eTrapp configuration. New
              accounts will automatically open to the installation wizard. To add additional eTrapp units select “add
              eTrapp” under the “Manage My eTrapp” tab</p>
            <p>Now, enjoy peace of mind knowing that eTrapp is monitoring your AC unit’s health!</p>
            <p>**These instructions are supplied as a guideline for installation. The HVAC professional will encounter
              many different configurations when installing the eTrapp. eDelta does not represent to have addressed
              every possible configuration that may be encountered in the market.</p>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
    <div class="modal-footer">
      <button class="btn btn-outline-primary" type="button" (click)="acknowledgeGuidelines()"> I Acknowledge </button>
      <button class="btn btn-outline-danger" type="button" (click)="closeInstructionsModal()">
        Close
      </button>
    </div>
  </div>
</ng-template>