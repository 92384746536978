import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { GlobalService } from 'src/app/shared/services/global.service';
import { RequestServices } from 'src/app/shared/services/request.services';

@Component({
  selector: 'app-acknowlege',
  templateUrl: './acknowlege.component.html',
  styleUrls: ['./acknowlege.component.scss']
})
export class AcknowlegeComponent implements OnInit {

  @ViewChild('content') public templateRef: TemplateRef<any>;
  checklist: any;
  etrappaddress: any

  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'modal-dialog-centered modal-xl',
  };
  minDate: any = new Date().toISOString().split('T')[0];

  modalRefChild: BsModalRef
  ackForm: FormGroup;
  today = new Date().toISOString().split("T")[0];
  constructor(private modalService: BsModalService,
    public global: GlobalService,
    private service: RequestServices,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.initAckForm();

  }


  initAckForm() {
    this.ackForm = this.formBuilder.group({
      id: [0, Validators.compose([Validators.nullValidator])],
      assignedProStaffId: ["", Validators.compose([Validators.required])],
      appointmentOn: [null, Validators.compose([Validators.required])],
      appointmentStartTime: [null, Validators.compose([Validators.required])],
      appointmentEndTime: [null, Validators.compose([Validators.required])],
      selectedLogIds: [[], Validators.compose([Validators.required])]
    });
  }

  onCheckboxChange(event, data) {
    const selectedLogIds = this.ackForm.get('selectedLogIds').value as any[];
    if (event.target.checked) {
      selectedLogIds.push(data);
    } else {
      const index = selectedLogIds.findIndex(item => item.proid === data.proid && item.consumerId === data.consumerId);
      if (index >= 0) {
        selectedLogIds.splice(index, 1);
      }
    }
    this.ackForm.get('selectedLogIds').setValue(selectedLogIds);
  }

  @Output() successEvent = new EventEmitter<void>();

  submitAckForm(obj: any) {
    obj.proId = this.global.getProObj().id;
    obj.consumerId = this.customerid;
    obj.serviceAddres = this.etrappaddress

    this.global._show_loader = true;
    this.service
      .postRequest(`SupportTicket/AddSupportTicketWithDetails`, obj)
      .subscribe(
        (response) => {
          if (response.statusCode == "201") {
            this.global._show_loader = false;
            this.global.showNotification('Success', '', response.status);
            this.successEvent.emit();
            this.modalRefChild.hide();
          }
        },
        (error) => {
          this.global.showNotification('Error', '', 'Error Acknowledging ticket');
          this.global._show_loader = false;

        }
      );
  }

  cartridgeVar = {
    proArr: [],
    timeArr: [{ time: "8:00 am" }, { time: "8:30 am" }, { time: "9:00 am" }, { time: "9:30 am" }, { time: "10:00 am" }, { time: "10:30 am" }, { time: "11:00 am" }, { time: "11:30 am" }, { time: "12:00 pm" }, { time: "12:30 pm" }, { time: "1:00 pm" }, { time: "1:30 pm" }, { time: "2:00 pm" }, { time: "2:30 pm" }, { time: "3:00 pm" }, { time: "3:30 pm" }, { time: "4:00 pm" }, { time: "4:30 pm" }, { time: "5:00 pm" }],
    endTimeArr: [],
    timeDifferenceArr: ['30 minutes', '1 hour', '1.5 hours', '2 hours', '2.5 hours', '3 hours', '3.5 hours', '4 hours', '4.5 hours', '5 hours', '5.5 hours', '6 hours', '6.5 hours', '7 hours', '7.5 hours', '8 hours', '8.5 hours', '9 hours'],
  }
  customerid: any


  openmodal(address: any, customerid: any, alertgenerated) {
    this.customerid = customerid
    this.etrappaddress = address
    this.getAllProStaff();
    this.getchecklist(address);
     const datetovalidate = this.global.utcDateToLocal(alertgenerated);
    const datetovalidatee = new Date(datetovalidate);
    this.ackForm.controls.appointmentStartTime.clearValidators()
    this.ackForm.controls.appointmentStartTime.setValidators([this.appointmentTimeValidator(datetovalidatee)]);

  }

  appointmentTimeValidator(datetovalidate: Date): ValidatorFn {
    const timern= new Date();
    if (datetovalidate <timern){
      datetovalidate=timern
    }
    

    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const selectedTime = control.value;
      if (!selectedTime) {
        return null;
      }
      const appointmentOnValue = this.ackForm.get('appointmentOn')?.value;
      const selectedDate = new Date(appointmentOnValue);
      const [time, modifier] = selectedTime.split(" ");
      let [hours, minutes] = time.split(":").map(Number);
      if (modifier === "pm" && hours < 12) {
        hours += 12;
      } else if (modifier === "am" && hours === 12) {
        hours = 0;
      }
      selectedDate.setHours(hours, minutes, 0);
      if (selectedDate > datetovalidate) {
        return null;
      }
      return { appointmentTimeInvalid: true };
    };
  }
  getchecklist(address: any) {
    this.service.getRequest(`AcknowledgedAlerts/GetUnServicedAlertsByAddress?ServiceAddrress=${address}`)
      .subscribe((response) => {

        if (response.statusCode === 200) {
          this.checklist = response.data
          this.global._show_loader = false;
          this.modalRefChild = this.modalService.show(this.templateRef, this.config);

        }
        else {
          this.global._show_loader = false;
          this.global.showNotification('Error', '', 'No Record exist');
        }
      }, (error) => {
        this.global.showNotification('Error', '', 'Something Went Wrong');
        this.global._show_loader = false;
      });


  }
  closeModel() {
    this.modalRefChild.hide();
    this.modalRefChild = undefined;
  }

  onDateChange() {
    this.ackForm.patchValue({
      appointmentStartTime: null,
      appointmentEndTime: null
    });
  }

  getTime(obj: any, isEdit?: any) {
    let indexOfTime = this.cartridgeVar.timeArr.findIndex(x => x.time === obj);
    let cloneArray = [...this.cartridgeVar.timeArr];
    this.cartridgeVar.endTimeArr = cloneArray.splice(indexOfTime + 1, cloneArray.length);
    if (this.ackForm.value.appointmentEndTime !== null && !isEdit) {
      this.ackForm.controls['appointmentEndTime'].setValue("")
    }
    this.cartridgeVar.endTimeArr.map((item: any, index) => {
      return item.diff = " - " + this.cartridgeVar.timeDifferenceArr[index];
    });
  }

  getAllProStaff() {
    this.global._show_loader = true;
    this.service.getRequest(`ProStaff/GetAllProStaffByProId?ProId=${this.global.getProObj().id}`)
      .subscribe(
        (response) => {
          this.global._show_loader = false;
          if (response.statusCode == "200") {
            
            this.cartridgeVar.proArr = response.data;

          }
          else {
            this.global.showNotification("Error", "", "Something went wrong while fetching pro data.");
          }
        },
        (error) => {
          this.global.showNotification("Error", "", "Something went wrong while fetching pro data.");
        }
      );
  }

}
