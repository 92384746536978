import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SortDirective } from './shared-directives/sort.directives';

@NgModule({
  declarations: [
    SortDirective
  ],
  imports: [
    RouterModule,
    CommonModule,
   
  ],
  exports: [
    SortDirective,
    RouterModule,
    CommonModule,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})
export class SharedDirectivesModule{ }
