import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingComponent implements OnInit {


  @Input() showRating: boolean;


  @Input() rating: number = 0;
  @Input() totalStars: number = 5;

  stars: number[] = [];

  ngOnInit() {
    this.stars = Array(this.totalStars).fill(0).map((x, i) => i + 1);
  }

  getStarType(star: number): string {
    if (star <= Math.floor(this.rating)) {
      return 'fa-star'; 
    } else if (star === Math.floor(this.rating) + 1 && this.rating % 1 !== 0) {
      if (this.rating % 1 >= 0.90) {
        return 'fa-star';  
      } else if (this.rating % 1 >= 0.25) {
        return 'fa-star-half-o';  
      }
    }
    return 'fa-star-o';  
  }

}
