import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConsumerModalComponent } from './consumer-modal-component/consumer-modal.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgSelectModule } from '@ng-select/ng-select';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ComponentsChartModule } from 'src/app/components/bootstrap/charts/components.charts.module';
import { ChartModule } from 'angular-highcharts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AcknowlegeComponent } from './acknowlege/acknowlege.component';
import { DashboardModule } from 'src/app/layout/dashboard/dashboard.module';
import { DashboardComponent } from 'src/app/layout/dashboard/dashboard-component/dashboard.component';
import { TimeDifferencePipe } from '../../pipe/transformpipe.pipe';



@NgModule({
  declarations: [
    ConsumerModalComponent,
    AcknowlegeComponent,
    TimeDifferencePipe
  ],
  imports: [
    CommonModule,
    TabsModule.forRoot(),
    PerfectScrollbarModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
    ComponentsChartModule,
    ChartModule,
    
  ],
  exports:[
    ConsumerModalComponent,
    AcknowlegeComponent
  ]
})
export class ConsumerModalModule { }
