

<app-acknowlege #ackowlegeModal  (successEvent)="onChildSuccess()"></app-acknowlege>
<ng-template #content>
  <div class="modal-header bg-primary p-3">
    <div class="row">
      <div class="col-lg-10">
        <div class="row">
          <div class="col-lg-6" style="font-size:18px">{{customerobj.name}}</div>
          <div class="col-lg-6" style="font-size:18px">
            <i class="fa fa-solid fa-phone mr-2"></i> {{customerobj.phoneNumber}}</div>
          <div class="col-lg-6" style="font-size:18px">
            <i class="fa fa-solid fa-envelope mr-2"></i>{{customerobj.email}}
          </div>
          <div class="col-lg-6" style="font-size:18px">Customer since: {{createdcustomer | timeDifference}}</div>
          <div>{{createdcustomer}}</div>

        </div>

      </div>
      <!-- <div class="col-lg-6 d-flex justify-content-end"> -->
      <!-- <button type="button" class="close pull-right text-light" aria-label="Close"
      (click)="closeModel();">
      <span aria-hidden="true">&times;</span>
    </button> -->
      <!-- </div> -->
    </div>

    <button type="button" class="close pull-right text-light" aria-label="Close"
      (click)="closeModel();">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body" style="padding-top:12px">
    <div class=" scroll  mb-5">
      <perfect-scrollbar class="scroll" 
        [config]="{ suppressScrollX: false,  wheelPropagation: false }">
        <div class="row" *ngIf="activealert">
          <div class="col-6">
            <h2 class="fw-bold">eTrapp Status</h2>
          </div>
          <div class="col-6 d-flex justify-content-end">
            <button class="btn btn-primary" (click)="openacknowlege()">Acknowledge</button>
          </div>

        </div>
        <div class="row  second-row mt-1" *ngIf="activealert">
          <div class="col-3 device-unit">
            <div class="row">
              <h5 class="text-primary fw-bold mt-2" *ngIf="etrappdetails.waterLevel >=0"><i
                  class="fa fa-regular fa-circle text-success mr-2"></i>Water level: {{etrappdetails.waterLevel}}%</h5>
                  <h5 class="text-danger fw-bold mt-2" *ngIf="etrappdetails.waterLevel <0"><i
                    class="fa fa-regular fa-circle text-danger mr-2"></i>Disconnected</h5>
            </div>
            <div class="row">
              <div class="col-5">
                <img class="ml-3"
                [src]="getWaterLevelImage(etrappdetails.waterLevel)" style="width: 44px; height: 62px;"
                  alt>
              </div>
              <div class="col-7">
                <p class="mb-0">Device</p>
                <h5>{{this.customerdevice}}</h5>
              </div>

            </div>
          </div>
          <div class="col-3 active-alert" >
            <h3 class="text-primary fw-bold">Last Alert:</h3>
            <div class="active-alert-child mb-2">
              <h5>{{activealert}}</h5>
            </div>
            <p class="text-end">{{alertgenerated   | timeDifference}}</p>
          </div>
          <div class="col-3 important-div">
            <h3 class="text-primary fw-bold">Important:</h3>
            <ul>
              <li class="fw-bold text-primary fs-4" *ngIf="etrappdetails.airFilter==null">No Air Filters found</li>

              <li class="fw-bold text-danger fs-4" *ngIf="zerodays">Air Filter change due</li>
              <!-- <div *ngIf="etrappdetails.airFilter==null"> -->
                <!-- <ng-template #airfilterexist> -->
              <li class="fw-bold" *ngIf="!zerodays && etrappdetails.airFilter!==null">Air Filter change due in <span
                class="text-success">{{calculateRemainingfilterDays(etrappdetails.airFilter.installedOn,
                  etrappdetails.airFilter.remainingDays)}} days</span></li>
                  <!-- </ng-template> -->
                 
              <li class="fw-bold" *ngIf="!zerocatridgedays">Cartridge change in <span
                  class="text-success">{{etrappdetails.remainingCartridgeDays}} days</span></li>
                  <li class="fw-bold text-danger fs-4" *ngIf="zerocatridgedays">Catridge change due</li>
            </ul>
          </div>
          <div class="col-3 service-address">
            <h3 class="text-primary fw-bold">Service Address:</h3>
            <h4>{{etrappdetails.etrappAddress }},
              {{etrappdetails.etrappCity }},
              {{etrappdetails.etrappState }},
              {{etrappdetails.etrappCountry }}
            </h4>
          </div>
        </div>

        <div class="row third-row mt-2">
          <div class="col-lg-5" *ngIf="activealert">
            
            <div class="row d-flex justify-content-between">

              <div class=" thirdRow-first">
                <div class="row">
                  <div class="col-5 mt-4 text-end">
                    <img class="ml-3"
                      src="../../../../../assets/img/image 5.svg"
                      alt>
                  </div>
                  <div class="col-7 mt-3">
                    <p class="mb-0">Cartridge</p>
                    <h5>{{etrappdetails.remainingCartridgeLife}}%</h5>
                  </div>

                </div>
              </div>
              <div class=" thirdRow-second">
                <div class="row">
                  <div class="col-5 mt-4 text-end">
                    <img class="ml-3" *ngIf="etrappdetails.cartridgeLock == 0"
                      src="../../../../../assets/img/unlock_icon.png" 
                      alt>
                      <img class="ml-3" *ngIf="etrappdetails.cartridgeLock == 1"
                      src="../../../../../assets/img/Lock_icon.png" 
                      alt>
                  </div>
                  <div class="col-7 mt-3">
                    <p class="mb-0">Status</p>
                    <h5>{{etrappdetails.cartridgeLock== 0? 'Unlock': 'Lock'}}</h5>
                  </div>

                </div>
              </div>
              <div class=" thirdRow-third">
                <div class="row">
                  <div class="col-5 mt-4 text-end">
                    <img class="ml-3"
                      src="../../../../../assets/img/Group.svg"
                      alt>
                  </div>
                  <div class="col-7 mt-3" >
                    <p class="mb-0">Pan</p>
                    <h5>{{etrappdetails.panFlood==0?'OK': 'Flooded'}}</h5>
                  </div>

                </div>
              </div>

            </div>
            <div class="row third-row fourth-row mt-2 p-0">
              <div class="col-lg-12">
                <div class="row d-flex justify-content-between">
                  <!-- <div>554</div> -->
                  <div class=" thirdRow-first">
                    <div class="row">
                      <div class="col-5 mt-4 text-end">
                        <img class="ml-3"
                          [src]="getWiFiImage(etrappdetails.isOffline,etrappdetails.wiFiQuality)"
                          style="width: 30px; height:22px"alt>
                      </div>
                      <div class="col-7 mt-3">
                        <p class="mb-0">Wi-Fi</p>
                        <h5>{{etrappdetails.isOffline==1? 'Offline': etrappdetails.wifi +' dB'}} </h5>
                      </div>

                    </div>
                  </div>
                  <div class="thirdRow-first" 
                   [ngStyle]="!etrappdetails.isAcCutoffInstalled ? {'background-color': 'lightgray'} : {'background-color': 'white'}">

                    <div class="row">
                      <div class="col-5 mt-4 text-end">
                        <img class="ml-3"
                          src="../../../../../assets/img/ac_on.png" *ngIf="etrappdetails.acCutoff == 0"
                          alt style="width: 29px; height: 23px;">
                          <img class="ml-3"
                          src="../../../../../assets/img/ac-off.png" *ngIf="etrappdetails.acCutoff !== 0"
                          alt style="width: 29px; height: 23px;">
                      </div>
                      <div class="col-7 mt-3">
                        <p class="mb-0">A/C</p>
                        <h5 *ngIf="etrappdetails.isAcCutoffInstalled">{{etrappdetails.acCutoff==0? 'ON': 'OFF'}}</h5>
                      </div>
                    </div>
                  </div>
                  <div class=" thirdRow-first">
                    <div class="row">
                      <div class="col-5 mt-4 text-end">
                        <img class="ml-3"
                          [src]="getbatteryImage(etrappdetails.battery)" style="width:16px; height: 30px;"
                          alt>
                      </div>
                      <div class="col-7 mt-3">
                        <p class="mb-0">Battery</p>
                        <h5>{{etrappdetails.battery}}%</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           
            <div class="row  mt-2 fourthRow">
              <div class="col-lg-12 d-flex justify-content-between align-items-center">
                <div class=" align-item">
                  <img src="../../../../../assets/img/image 5.svg" alt="" height="40px">
                </div>
                <div class="mt-3">
                  <p style="font-size: 18px"><b>Cartridge</b></p>
                </div>
                <div class="mt-3">
                  <h5 class="mb-0">Last Change:</h5>
                  <p class="no-m">{{etrappdetails.installedOn | date :'MMM d, y'}}</p>
                  <p class="text-end mb-0">{{etrappdetails.installedOn | date :' h:mm a'}}</p>


                </div>
                <div class="mt-3">
                  <h5 class="mb-0">Remaining</h5>
                  <p class="no-m">{{etrappdetails.remainingCartridgeLife}}%</p>
                  <p class="mb-0">{{etrappdetails.remainingCartridgeDays}} days</p>
                </div>

              </div>
            </div>
            <div class="row  mt-2 fourthRow" *ngIf="etrappdetails.airFilter !==null">
              <div class="col-lg-12 d-flex justify-content-between align-items-center">
                <div class=" align-item">
                  <img src="../../../../../assets/img/image 6.svg" alt="" height="40px">
                </div>
                <div class="mt-3">
                  <p style="margin-bottom:0px">Air Filter</p>
                  <p style="font-size: 15px"  
                  ><b>{{etrappdetails.airFilter.filterSize}}</b></p>
                  
                </div>
                <div class="mt-3">
                  <h5 class="mb-0">Last Change:</h5>
                  <p class="no-m" >{{etrappdetails.airFilter.installedOn | date :'MMM d, y'}}</p>
                  <p class="text-end mb-0" >{{etrappdetails.airFilter.installedOn | date :' h:mm a'}}</p>


                </div>
                <div class="mt-3">
                  <h5 class="mb-0">Remaining</h5>
                  <p class="no-m">{{remainingFilterpercent}}%</p>
                  <p class="mb-0">{{calculateRemainingfilterDays(etrappdetails.airFilter.installedOn,
                    etrappdetails.airFilter.remainingDays)}} days</p>
                </div>

              </div>
            </div>
            <div class="row  mt-2 fourthRow" *ngIf="etrappdetails.airFilter ==null">
              <div class="col-lg-12 d-flex justify-content-center align-items-center">
                <h3 style="padding:31px 0px">No Air Filters Found</h3>
              </div>
              </div>

            
          </div>

          <!-- DIFFERENT ETRAPPS -->
          <div class="col-lg-5 " *ngIf="!activealert" style="height: 475px;">
            <!-- <div class=" scroll  mb-5"> -->

            <perfect-scrollbar class="scroll custom-scroll" [config]="{ suppressScrollX: true, wheelPropagation: false }"
            >
            <div class="class" *ngFor="let etrappdetails of etrappdetails" >
            <div class="row d-flex justify-content-between  mt-3" >

              <div class="col-12 device-unit-2  mb-2 d-flex pt-2 pb-2">
                <div class="col-6 unit">
                <div class="row">
                  <h5 class="text-primary fw-bold " *ngIf="etrappdetails.waterLevel >=0"><i
                      class="fa fa-regular fa-circle text-success mr-2"></i>Water level: {{etrappdetails.waterLevel}}%</h5>
                      <h5 class="text-danger fw-bold " *ngIf="etrappdetails.waterLevel <0"><i
                        class="fa fa-regular fa-circle text-danger mr-2"></i>Disconnected</h5>
                </div>
                <div class="row">
                  <div class="col-5">
                    <img class=" mb-1"
                    [src]="getWaterLevelImage(etrappdetails.waterLevel)" style="width: 44px; height: 62px;"
                      alt>
                  </div>
                  <div class="col-7 p-0" >
                   
                    <h5>{{etrappdetails.deviceName}}</h5>
                  </div>
    
                </div>
              </div>
                <div class="col-6 service-address" style="border-left: 1px solid lightgray;">
                  <h5 class="text-primary fw-bold">Service Address:</h5>
                  <p class="mb-0">{{etrappdetails.etrappAddress }},
                    {{etrappdetails.etrappCity }},
                    {{etrappdetails.etrappState }},
                    {{etrappdetails.etrappCountry }}
                  </p>
                </div>
              </div>
              
              
              
              

            </div>
            <div class="row d-flex justify-content-between">

              <div class=" thirdRow-first">
                <div class="row">
                  <div class="col-5 mt-4 text-end">
                    <img class="ml-3"
                      src="../../../../../assets/img/image 5.svg"
                      alt>
                  </div>
                  <div class="col-7 mt-3">
                    <p class="mb-0">Cartridge</p>
                    <h5>{{etrappdetails.remainingCartridgeLife}}%</h5>
                  </div>

                </div>
              </div>
              <div class=" thirdRow-second">
                <div class="row">
                  <div class="col-5 mt-4 text-end">
                    <img class="ml-3" *ngIf="etrappdetails.cartridgeLock == 0"
                      src="../../../../../assets/img/unlock_icon.png" 
                      alt>
                      <img class="ml-3" *ngIf="etrappdetails.cartridgeLock == 1"
                      src="../../../../../assets/img/Lock_icon.png" 
                      alt>
                  </div>
                  <div class="col-7 mt-3">
                    <p class="mb-0">Status</p>
                    <h5>{{etrappdetails.cartridgeLock== 1? 'Lock': 'Unlock'}}</h5>
                  </div>

                </div>
              </div>
              <div class=" thirdRow-third">
                <div class="row">
                  <div class="col-5 mt-4 text-end">
                    <img class="ml-3"
                      src="../../../../../assets/img/Group.svg"
                      alt>
                  </div>
                  <div class="col-7 mt-3" >
                    <p class="mb-0">Pan</p>
                    <h5>{{etrappdetails.panFlood==0?'OK': 'Flooded'}}</h5>
                  </div>

                </div>
              </div>

            </div>
            <div class="row third-row fourth-row mt-2 p-0">
              <div class="col-lg-12">
                <div class="row d-flex justify-content-between">
                  <!-- <div>554</div> -->
                  <div class=" thirdRow-first">
                    <div class="row">
                      <div class="col-5 mt-4 text-end">
                        <img class="ml-3"
                          [src]="getWiFiImage(etrappdetails.isOffline,etrappdetails.wiFiQuality)"
                          style="width: 30px; height:22px"alt>
                      </div>
                      <div class="col-7 mt-3">
                        <p class="mb-0">Wi-Fi</p>
                        <h5>{{etrappdetails.isOffline==1? 'Offline': etrappdetails.wifi +' dB'}} </h5>
                      </div>

                    </div>
                  </div>
                  <div class=" thirdRow-first">
                    <div class="row">
                      <div class="col-5 mt-4 text-end">
                        <img class="ml-3"
                          src="../../../../../assets/img/ac_on.png" *ngIf="etrappdetails.acCutoff == 0"
                          alt style="width: 29px; height: 23px;">
                          <img class="ml-3"
                          src="../../../../../assets/img/ac-off.png" *ngIf="etrappdetails.acCutoff !== 0"
                          alt style="width: 29px; height: 23px;">
                      </div>
                      <div class="col-7 mt-3">
                        <p class="mb-0">A/C</p>
                        <h5>{{etrappdetails.acCutoff==0? 'ON': 'OFF'}}</h5>
                      </div>

                    </div>
                  </div>
                  <div class=" thirdRow-first">
                    <div class="row">
                      <div class="col-5 mt-4 text-end">
                        <img class="ml-3"
                          [src]="getbatteryImage(etrappdetails.battery)" style="width:16px; height: 30px;"
                          alt>
                      </div>
                      <div class="col-7 mt-3">
                        <p class="mb-0">Battery</p>
                        <h5>{{etrappdetails.battery==null? 0: etrappdetails.battery}}%</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           
            <div class="row  mt-2 fourthRow">
              <div class="col-lg-12 d-flex justify-content-between align-items-center">
                <div class=" align-item">
                  <img src="../../../../../assets/img/image 5.svg" alt="" height="40px">
                </div>
                <div class="mt-3">
                  <p style="font-size: 18px"><b>Cartridge</b></p>
                </div>
                <div class="mt-3">
                  <h5 class="mb-0">Last Change:</h5>
                  <p class="no-m">{{etrappdetails.installedOn | date :'MMM d, y'}}</p>
                  <p class="text-end mb-0">{{etrappdetails.installedOn | date :' h:mm a'}}</p>


                </div>
                <div class="mt-3">
                  <h5 class="mb-0">Remaining</h5>
                  <p class="no-m">{{etrappdetails.remainingCartridgeLife}}%</p>
                  <p class="mb-0">{{etrappdetails.remainingCartridgeDays}} days</p>
                </div>

              </div>
            </div>
            <div class="row  mt-2 fourthRow" *ngIf="etrappdetails.airFilter !==null">
              <div class="col-lg-12 d-flex justify-content-between align-items-center">
                <div class=" align-item">
                  <img src="../../../../../assets/img/image 6.svg" alt="" height="40px">
                </div>
                <div class="mt-3">
                  <p style="margin-bottom:0px">Air Filter</p>
                  <p style="font-size: 15px"  
                  ><b>{{etrappdetails.airFilter.filterSize}}</b></p>
                  
                </div>
                <div class="mt-3">
                  <h5 class="mb-0">Last Change:</h5>
                  <p class="no-m" >{{etrappdetails.airFilter.installedOn | date :'MMM d, y'}}</p>
                  <p class="text-end mb-0" >{{etrappdetails.airFilter.installedOn | date :' h:mm a'}}</p>


                </div>
                <div class="mt-3">
                  <h5 class="mb-0">Remaining</h5>
                  <p class="no-m">{{remainingFilterpercent}}%</p>
                  <p class="mb-0">{{calculateRemainingfilterDays(etrappdetails.airFilter.installedOn,
                    etrappdetails.airFilter.remainingDays)}} days</p>
                </div>

              </div>
            </div>
            <div class="row  mt-2 fourthRow" *ngIf="etrappdetails.airFilter ==null">
              <div class="col-lg-12 d-flex justify-content-center align-items-center">
                <h3 style="padding:12px 0px">No Air Filters Found</h3>
              </div>
              </div>
            </div>
          </perfect-scrollbar>
        

            
          </div>
          <div class="col-lg-7">
            <div class="card" #eTrappAlerts id="eTrappAlerts">
              <div class="card-body">
                
                  <div class="d-inline-block">
                    <h4 class="d-inline card-title">Other upcoming Service Recommendations</h4>
                  </div>
                
                
                <div class=" d-flex justify-content-between">
                <div class="float-left float-none-xs" >

                  <input type="text"  placeholder="Search by Address" class="mt-2 px-2 py-1" style="border: 1px solid #145388; border-radius: 10px;"
                  [(ngModel)]="address" (change)="loadservices()">
                  </div>

                  <div class="float-right float-none-xs">
                    <!-- <button class="test" style="font-size: 10px; border: 0px; margin-right: 10px;">Add Service Ticket</button> -->
                    <span class="mr-1 font-weight-bold ">Show</span>
            <div class="btn-group " dropdown style="position: relative;">
              <button id="button-basic" dropdownToggle type="button"
                class="btn btn-outline-primary btn-xs dropdown-toggle p-2" aria-controls="dropdown-basic">
                {{dashboardVar.serviceselectedvalue.label}} <span class="caret"></span>
              </button>
              <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                <li role="menuitem" *ngFor="let item of itemOptionsOrders ; let i = index">
                  <a *ngIf="i !== 0" (click)="onChangeOrderBy(item,1)" class="c-pointer"
                    [ngClass]="dashboardVar.serviceselectedvalue.label === item.label? 'dropdown-item active': 'dropdown-item'">
                    {{ item.label }}
                  </a>
                </li>
              </ul>
                      <button class="header-icon btn btn-empty d-none d-sm-inline-block p-0 ml-3 mr-4 " type="button"
                        id="fullScreenButton" (click)="fullScreenClickWidget(eTrappAlerts)">
                        <i *ngIf="!isFullScreen" class="simple-icon-size-fullscreen"></i>
                        <i *ngIf="isFullScreen" class="simple-icon-size-actual"></i>
                      </button>
                    </div>
                    <!-- <div class="position-absolute card-top-buttons mt-4 mr-3 p-0 pt-1 ">
                      <button class="btn btn-header-light icon-button pl-0" >
                        <i class="simple-icon-refresh p-0 m-0"></i>
                      </button>
                    </div> -->
                  </div>
                </div>
              </div>
              <div class="card-body pt-0">
                <div [ngClass]="{
                  'fullScreenClass': isFullScreen === true,
                  'dashboard-list-with-user': !isFullScreen,
                  'dashboard-list-with-activealert': !activealert
                }">
                  <perfect-scrollbar class="scroll" [config]="{ suppressScrollX: true, wheelPropagation: false }">
                    <table class="table">
                      <thead class="lead-th">
                        <tr>
                          <th scope="col">eTrapp Device</th>
                          <th class="" scope="col ">Service Type</th>
                          <th scope="col" class="">Service Address</th>
                        </tr>
                      </thead>
                      <tbody *ngIf="serviceslist.length> 0; else nodata" >
                        <tr *ngFor="let data of serviceslist">
                          <td >{{data.deviceName}}</td>
                          <td>{{data.definition}}</td>
                          <td>{{data.address}}</td>
                        </tr>
                      </tbody>
                      <ng-template #nodata>
                        <tbody>
                          <tr>
                            <td class="text-center" colspan="4">
                              <p class="card-title">
                                No appointments Found
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </ng-template>
                    </table>
                  </perfect-scrollbar>
                </div>
              </div>
            </div>

          </div>
        </div>



        <div class="row mt-3" >
          <div class="col-7">
            <div class="card">
              <div class="card-body">
                <div [ngClass]="isFullScreen==true ? 'fullScreenClass' : 'dashboard-list-with-appointments' ">
                  <perfect-scrollbar class="scroll" [config]="{ suppressScrollX: true, wheelPropagation: false }">
                <div class="table-responsive">
                  <table class="table table-striped">
                    <thead class="cellspacinginline" >
                      <tr>
                        <th class="text-left" scope="col">
                         #
                        </th>
                        <th class="text-left" scope="col">Appointment date</th>
                        <th class="text-left" scope="col">Employee</th>
                        <!-- <th class="text-center" scope="col">Customer Name</th>
                        <th class="text-center" scope="col">Customer Phone</th> -->
                        <!-- <th class="text-center" scope="col">Customer Email</th> -->
                        <th class="text-left" scope="col">Service</th>
                      </tr>
                    </thead>
                    <tbody *ngIf="appointmentlist.length> 0; else nodata" >
                      <tr *ngFor="let data of appointmentlist let i=index" >
                        <td>{{i+1}}</td>
                        <td >{{data.appointmentOn | date:'MMM d, y'}}</td>
                        <td>{{data.technician}}</td>
                        <td>{{data.details}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </perfect-scrollbar>
              </div>
                
              </div>
            </div>
          </div>
          <div class="col-5">
            <div class="card">
              <div class="card-body">
                <div class="form">

                  <form [formGroup]="objSafetyCheckList" (ngSubmit)="addcomment(objSafetyCheckList.value)" novalidate>
            <div class="row d-flex justify-content-between mb-2" style="padding: 0 22px;">
                  <div class="heading">
                    <h4>Comments</h4>
                  </div>

                  <button tabindex="9"  [disabled]="!objSafetyCheckList.valid  " *ngIf="cmntforadd"
               class="btn  btn-primary text-white btn-xs pull-right" type="submit" tabindex="12">
              Add Comment</button>
              <button type="button" (click)="updatecomment(objSafetyCheckList.value)"  *ngIf="cmntforedit"
        tabindex="12" 
        class="btn  btn-primary text-white btn-xs pull-right">Update Comment
      </button>
            </div>
                
                <textarea class=" rounded" rows="4" placeholder="Enter comments here"
                class="form-control" formControlName="comment"></textarea>
    </form>
  </div>
                <div [ngClass]="isFullScreen==true ? 'fullScreenClass' : 'dashboard-list-with-comments' ">
                  <perfect-scrollbar class="scroll" [config]="{ suppressScrollX: true, wheelPropagation: false }">
            <table class="table">
              <thead class="cellspacinginline" style = 'font-size: 12px !important;'>
                <th>Date time</th>
                <th>Note</th>
                <!-- <th >By</th> -->
                <th >Action</th>
              </thead>
              <tbody>
                <tr *ngFor="let device of comments">
                 
                  <td class="cellspacinginline">
                    <p class="text-small">
                      {{device.createdAt | date: 'MMM d, y, h:mm a' :''}}
                    </p>
                  </td>
                  <td>
                    <p class="text-small">
                      {{device.comment != null? device.comment : ''}}
                    </p>
                  </td>
                  <!-- <td>
                      {{device.name}}
                  </td> -->
                  <td><i class="bi bi-pencil" (click)="openModalForUpdate(device.id)"></i></td>
                </tr>
              </tbody>
            </table>
          </perfect-scrollbar>
          </div>
            </div>
            </div>

          </div>
        </div>

        <div class="row mt-4  d-flex justify-content-between mb-5" style="padding: 0px 20px;"  >
          <div class="metrics">
          <h1>Customer | Metrics</h1>
          </div>
          <div class="search-bar d-flex">
            
        <ng-select class="w-100 mr-2 rounded" [items]="etrapps" bindLabel="nickName" 
        placeholder="Search eTrapp Unit" bindValue="macId"   (change)="selectMacID($event)" [(ngModel)]="selectedmacid"
        (clear)="clearmacid()" 
         >
        </ng-select>
        <!-- <span class="mr-1 font-weight-bold ">Show</span> -->
                  <div dropdown style="position: relative;">
                    <button id="button-basic" dropdownToggle type="button" 
                      class="btn btn-outline-primary btn-xs dropdown-toggle p-2" aria-controls="dropdown-basic">
                      {{dashboardVar.chartselectedvalue.label}} <span class="caret"></span>
                    </button>
                    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic" style="min-width: 0rem;">
                      <li role="menuitem"  *ngFor="let item of itemOptionsOrders; let i = index">
                        <a *ngIf="i !== 0" (click)="onChangeOrderBy(item,2)" class="c-pointer"
                          [ngClass]="dashboardVar.chartselectedvalue.label === item.label? 'dropdown-item active': 'dropdown-item'">
                          {{ item.label }}
                        </a>
                      </li>
                    </ul>
                  </div>
          </div>
        </div>

        <div class="row">
          
        <div class="col-lg-12 col-xmd-4 col-md-4"> 
          <div class="card mt-1 ">
            <div class="card-body text-center">
              <div class="card-chart-line" [ngClass]="!stock?'d-block':'d-none'">
                <p class="font-weight-bold position-relative p-nodata">No data to display</p>
              </div>
              <div class="d-block" [chart]="stock" #stockChart ></div>  
            </div>
          </div>
        </div>
        <div  class="col-lg-6 col-xmd-4 col-md-4"> 
          <div class="card mt-1 ">
            <div class="card-body text-center">
              <div class="card-chart-line" [ngClass]="!stock?'d-block':'d-none'">
                <p class="font-weight-bold position-relative p-nodata">No data to display</p>
              </div>
              <div class="d-block" [chart]="stock1" #stockChart ></div>  
            </div>
            
          </div>
        </div>
        <div class="col-lg-6 col-xmd-2 col-md-2"> 
          <div class="card mt-1 ">
            <div class="card-body text-center">
              <div class="card-chart-line" [ngClass]="!stock?'d-block':'d-none'">
                <p class="font-weight-bold position-relative p-nodata">No data to display</p>
              </div>
              <div [ngClass]="stock?'d-block':'d-none'">
              
                <div class="text-center">
                  <h2 class="d-block card-title text-center ">Air Filters</h2>
                </div>
              
                <div [ngStyle]="{'height.px': airfilters.length!== 0? 340 : null}" class="">
                  <perfect-scrollbar class="scroll" [config]="{ suppressScrollX: true, wheelPropagation: false }">
                    <table class="table"    >
                      <thead class="lead-th">
                        <tr>
                          <th scope="col">Unit</th>
                          <th class="" scope="col ">Address</th>
                          <th scope="col" class="">Size</th>
                          <th scope="col" class="">Remaining life</th>
      
                        </tr>
                      </thead>
                      <tbody *ngIf="airfilters.length> 0; else nofilter" >
                        <tr *ngFor="let data of airfilters">
                          <td >{{data.deviceName}}</td>
                          <td>{{data.address}}</td>
                          <td>{{data.filterSize}}</td>
                          <td>{{data.remainingDays}} days</td>
                        </tr>
                      </tbody>
                      <ng-template #nofilter>
                        <tbody style="height: 340px">
                          <tr>
                            <td class="text-center" colspan="4">
                              <p class="card-title">
                                No Filters Found
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </ng-template>
                    </table>
                  </perfect-scrollbar>
                </div>
              </div>
              
        </div>
        
      </div>
      </div>
        </div>


      </perfect-scrollbar>
    </div>
  </div>
  
</ng-template>




