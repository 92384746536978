import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard-component/dashboard.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GaugeChartModule } from 'angular-gauge-chart';

import { CollapseModule } from 'ngx-bootstrap/collapse';
import { SmallLineChartModule } from 'src/app/shared/shared-components/small-line-chart/small-line-chart.module';
import { ConsumerModalModule } from 'src/app/shared/shared-components/consumer-modal/consumer-modal.module';
import { SharedDirectivesModule } from 'src/app/shared/directives/shared-directives.modules';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge'
import more from 'highcharts/highcharts-more.src';
import { NgxSpinnerModule } from 'ngx-spinner';
import { RatingComponent } from 'src/app/shared/shared-components/rating/rating.component';
import { BarChartComponent } from 'src/app/components/bootstrap/charts/bar-chart/bar-chart.component';
import { ComponentsChartModule } from 'src/app/components/bootstrap/charts/components.charts.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { RatingModule } from 'src/app/shared/shared-components/rating/rating.module';
import { QRCodeModule } from 'angularx-qrcode';
export function highchartsModules() {
  return [more, HighchartsSolidGauge];
}

@NgModule({
  declarations: [DashboardComponent,
    
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    BsDropdownModule.forRoot(),
    ReactiveFormsModule,
    FormsModule,
    // SmallLineChartModule,
    CollapseModule.forRoot(),
    PerfectScrollbarModule,
    GaugeChartModule,
    ConsumerModalModule,
    SharedDirectivesModule,
    ChartModule,
    NgSelectModule,
    NgxSpinnerModule,
    ComponentsChartModule,
    TooltipModule.forRoot(),
    RatingModule,
    QRCodeModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    { provide: HIGHCHARTS_MODULES, useFactory: highchartsModules, }
  ] ,
  exports:[
    DashboardComponent
  ]
})
export class DashboardModule { }
