
<ng-template #content>
    <div class="modal-header bg-primary" style="padding:15px">
      <div class="">
         <h2>Acknowledge & Set Appointment</h2>
         </div>
         <button type="button" class="close pull-right text-light" aria-label="Close"
         (click)="closeModel();">
         <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form [formGroup]="ackForm" novalidate (ngSubmit)="submitAckForm(ackForm.value)">
      <div class="modal-body">
        <div class="row">
            <div class="col-6">
                <div class="heading">
                    
                    <h4>Other Upcoming Service Recommendations</h4>
                    <div class="block d-flex">
                          <h3>For service address:</h3>
                          <h4>{{checklist[0].address}}</h4>
                    </div>
                </div>
                <div class="card-body pt-0">
                <div style="height: 200px !important;;">
                  <perfect-scrollbar class="scroll" [config]="{ suppressScrollX: true, wheelPropagation: false }">
                    <table class="table">
                        <thead class="lead-th">
                            <tr>
                              <th scope="col"></th>
                              <th class="" scope="col ">Unit</th>
                              <th scope="col" class="">Alerts</th>
                            </tr>
                          </thead>
                      
                      <tbody *ngIf="checklist.length> 0; else nodata" >
                        
                        <tr *ngFor="let data of checklist">
                          <td >
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox"  id="flexCheckDefault"
                                [value]="data" 
                                (change)="onCheckboxChange($event,data)">
                              </div>
                          </td>
                          <td>{{data.deviceName}}</td>
                          <td>{{data.definition}}</td>
                        </tr>
                      </tbody>
                      <ng-template #nodata>
                        <tbody>
                          <tr>
                            <td class="text-center" colspan="4">
                              <p class="card-title">
                                No appointments Found
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </ng-template>
                    </table>
                  </perfect-scrollbar>
                </div>
              </div>
              </div>
            



            
            <div class="col-6">

                <div class="form-row mt-3">
                    
                    <div class="form-grop col-12 " style="
                    padding-top: 40px;">
                      <label class="font-weight-bold  ">Assign To
                        <span class="text-danger">*</span>
                      </label>
                      <select class="form-control" formControlName="assignedProStaffId">
                        <option selected disabled hidden value="">Select Technician</option>
                        <option value="" *ngIf="cartridgeVar.proArr.length==0" disabled>No Pro Found</option>
                        <option *ngFor="let pro of cartridgeVar.proArr" [ngValue]="pro.id">{{pro.firstname +' '+pro.lastname}}
                        </option>
                      </select>
                      <div *ngIf="ackForm.controls.assignedProStaffId.hasError('required')
                                  && ackForm.controls.assignedProStaffId.touched" class="invalid-tooltip">
                        Technician is required!
                      </div>
                    </div>
                    <div class="form-grop col-12 mt-1 ">
                      <label>Select Date & Time for service
                        <span class="text-danger">*</span>
                      </label>
                      <input type="date" class="form-control" placeholder="" formControlName="appointmentOn" [min]="minDate" 
                      (change)="onDateChange()"/>
                      <div *ngIf="ackForm.controls.appointmentOn.hasError('required')
                                  && ackForm.controls.appointmentOn.touched" class="invalid-tooltip">
                        Date is required!
                      </div>
                    </div>
                    <div class="mt-2 col-6">
                      <label>Select Start Time
                        <span class="text-danger">*</span>
                      </label>
                      <ng-select class="w-75  font-weight-normal custom" [clearable]="false" placeholder="Start Time"
                        formControlName="appointmentStartTime" (change)="getTime(ackForm.value.appointmentStartTime,false)">
                        <ng-container *ngFor="let time of cartridgeVar.timeArr;let i=index">
                          <ng-option *ngIf="i!=cartridgeVar.timeArr.length-1" class="" [value]="time.time">{{time.time}}
                          </ng-option>
                        </ng-container>
                      </ng-select>
                      <div *ngIf="ackForm.controls.appointmentStartTime.hasError('required')
                                  && ackForm.controls.appointmentStartTime.touched" class="invalid-tooltip">
                        Start Time is required!
                      </div>
                      <div *ngIf="ackForm.controls.appointmentStartTime.hasError('appointmentTimeInvalid')" >
                        <p class="text-danger m-0" style="font-size: 10px; line-height: 12px;">
                          Appointment start time must be later than alert generate time and current time.
                        </p>
                      </div>
                    </div>
                    <div class="mt-2 col-6">
                      <label>Select End Time
                        <span class="text-danger">*</span>
                      </label>
                      <ng-select [items]="cartridgeVar.endTimeArr" bindLabel="time" bindValue="time" placeholder="End Time"
                        class="w-75  font-weight-normal custom" [clearable]="false" formControlName="appointmentEndTime">
                        <ng-template ng-option-tmp let-item="item">
                          {{item.time}}
                          <small>{{item.diff}}</small>
                        </ng-template>
                      </ng-select>
                      <div *ngIf="ackForm.controls.appointmentEndTime.hasError('required')
                                  && ackForm.controls.appointmentEndTime.touched" class="invalid-tooltip">
                        End Time is required!
                      </div>
                      
                    </div>
                  </div>

                  <div class="button pull-right mt-5">
                    <button type="submit" class="btn btn-primary btn-md text-align-center" [disabled]="!ackForm.valid">
                      Acknowledge & Set Appointment
                    </button>
                  </div>

            </div>
        </div>

      </div>
    </form>

</ng-template>

