import { DatePipe } from '@angular/common';
import { Component, OnInit, Input, TemplateRef, ViewChild, EventEmitter, Output, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StockChart } from 'angular-highcharts';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalService } from "src/app/shared/services/global.service";
import { RequestServices } from 'src/app/shared/services/request.services';
import { AcknowlegeComponent } from '../acknowlege/acknowlege.component';


@Component({
  selector: 'app-consumer-modal',
  templateUrl: './consumer-modal.component.html',
  styleUrls: ['./consumer-modal.component.scss']
})
export class ConsumerModalComponent implements OnInit {
  @Input() details: any;
  @Output() test = new EventEmitter();
  @ViewChild('content') public templateRef: TemplateRef<any>;
  @ViewChild("ackowlegeModal") private acknowlegeModal: AcknowlegeComponent;
  comments: any=[]
  modalRef: BsModalRef;
  objSafetyCheckList: FormGroup;
  isFullScreen: any = false;
  arrowHead = "fa fa-sort";
  customerid: any;
  customerobj: any;
  customerdevice:any;
  etrappdetails:any;
  activealert: any;
  alerttime: any;
  etrapps: any
  airfilters: any=[]
  serviceslist: any=[]
  stock1: StockChart;
  stock: any
  selectedmacid: any;
  etappAddress: any;
  address: any=''



  itemOptionsOrders = [
    { label: "Days", value: "0" },
    { label: "1 day", value: "1day" },
    { label: "7 days", value: "7days" },
    { label: "30 days", value: "30days" },

  ];
  dashboardVar = {
    serviceselectedvalue: undefined,
    chartselectedvalue: undefined,
    previousChartsdataDate: undefined,
    previousserviceDate: undefined,
    previousEtrappDate: undefined,
    previousChartsdataDateOnInit: undefined,
    previousserviceDateOnInit: undefined,

  }

  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'modal-dialog-centered modal-xl',
  };
  configSmall={
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: "modal-md",
  };
  consumerModal = {
    userProfile: undefined,
  }


  constructor(
    public global: GlobalService,
    private modalService: BsModalService,
    private spinner: NgxSpinnerService,
    private datePipe: DatePipe,
    private formBuilder: FormBuilder,

  private services: RequestServices) { }
  modalRefChild: BsModalRef;
  @Output() successEvent = new EventEmitter<void>();

  onChildSuccess() {
    this.successEvent.emit();   
  }


  ngOnChanges() {
  }
  ngOnInit(): void {
    this.initForm();
    this.dashboardVar.serviceselectedvalue = this.itemOptionsOrders[3];
    this.dashboardVar.chartselectedvalue = this.itemOptionsOrders[0];
    this.getDateOnInit();

  }


  openacknowlege(){
    this.modalRefChild.hide();
    this.acknowlegeModal.openmodal(this.etappAddress, this.customerid, this.alertgenerated);
  }

  initForm() {
    this.objSafetyCheckList = this.formBuilder.group({
      id: [0, Validators.compose([Validators.required])],
      comment: [null, Validators.compose([Validators.required])],
      createBy: [0, Validators.required],
    });
  }
  
  
  getDateOnInit() {
    let currentDate = new Date();
    let previousWeekDate = new Date(
      currentDate.setDate(currentDate.getDate() - 30)
    );
      this.dashboardVar.previousserviceDateOnInit = previousWeekDate;
    this.dashboardVar.previousChartsdataDateOnInit = previousWeekDate;

    }

    alertgenerated: any
    createdcustomer: any

    openModal2(obj: any,) {
      debugger
      
      this.customerobj= obj.customer
      this.createdcustomer= new Date(this.customerobj.createdOn).toISOString();

      this.customerdevice= obj.deviceName
      this.customerid= obj.id
      this.activealert= obj.definition
      this.alertgenerated = obj.generatedOn
      if (this.activealert){
        this.getetrappdetails(obj.etrappUnitId);
      }
      else{
        this.getdifferentetrapps()
      }
      this.etappAddress= obj.etrappAddress
      
      this.getetrapps();
      this.loadservices();
      this.getallcomments();
      this.getallappoitmnets()
      this.getgraphsdata();

      // this.consumerModal.userProfile = obj;
      // this.etrappWarranty(obj.devices);
      // this.cartLife(obj.devices);
    }
    selectedetrapp: any
  
    selectMacID(event: any){ 
      this.selectedmacid= event.macId
    }
  clearmacid(){}


  addcomment(obj: any) {
    obj.proId=this.global.getProObj().id;
    obj.consumerId= this.customerid
    this.spinner.show()
    this.services
      .postRequest("ProComment/AddComment",obj).subscribe(
        (res) => {
          this.global.showNotification("Success", "", "Comment added successfully.");
          this.initForm()
          this.getallcomments()
          this.spinner.hide()

        });
  }
  updatecomment(obj: any) {
    obj.proId=this.global.getProObj().id;
    obj.consumerId= this.customerid
    this.spinner.show();
    this.services
      .postRequest("ProComment/UpdateComment",obj).subscribe(
        (res) => {
          this.global.showNotification("Success", "", "Comment updated successfully.");
          this.cmntforadd=true;
          this.cmntforedit=false
          this.initForm()
          this.getallcomments()
          this.spinner.hide()
          this.close()
        });
  }
  close() {
    try {
      this.modalRef.hide();
    } catch (error) {
      console.log("Exception at close: ", error);
    }
  }
  cmntforedit: boolean= false
  cmntforadd: boolean=true
  openModalForUpdate(obj: any) {
    this.cmntforedit=true
    this.cmntforadd=false
    try{
      this.spinner.show()
    this.services
      .getRequest(
        `ProComment/GetCommentById?Id=${obj}`
      )
      .subscribe(
        (res) => {
          if (res.statusCode === 200) {
              let obj = res.data[0];
              this.objSafetyCheckList.patchValue({
                comment: obj.comment,
                id: obj.id,
                createby: obj.CreateBy,
              });
             
            
            this.spinner.hide()
          }
        },
        (error) => {}
      );
    }catch(error){}
  }

  getetrapps() {
      this.services.getRequest(`EnterpriseEtrappsBatch/GetEtrappsBatchById?ConsumerId=${this.customerid}`)
        .subscribe((response) => {
          
          if (response.statusCode === 201) {
            
            this.etrapps= response.data
            if (this.etrapps.length==1){
              this.selectMacID(this.etrapps[0]);
              this.onChangeOrderBy(this.itemOptionsOrders[2], 2)
              this.loadChartsdata();
            }  
          }
          else {
            this.etrapps= []

            this.global.showNotification('Error', '', 'No Record exist');
          }
        }, (error) => {
          this.global.showNotification('Error', '', 'Something Went Wrong');
          
        });
         
    
  }
  getdifferentetrapps() {
    this.services.getRequest(`EtrappUnit/GetEtrappUnitsByConsumerId?consumerId=${this.customerid}`)
    .subscribe((response) => {
      if (response.statusCode == '200') {
        
        this.etrappdetails = response.data;
        this.etrappdetails.forEach((etrappdetails) => {
          if (etrappdetails.remainingCartridgeDays <= 0) {
            etrappdetails.remainingCartridgeDays = 0;
            this.zerocatridgedays = true;
          } else {
            this.zerocatridgedays = false;
          }
        
          if (etrappdetails.remainingCartridgeLife <= 0) {
            etrappdetails.remainingCartridgeLife = 0;
          }
        });
        
        this.modalRefChild = this.modalService.show(this.templateRef, this.config);
        this.global._show_loader = false;
      }
      else{
        this.etrappdetails = [];
      }
    }, (error) => {
      console.log(error);
    })
       
  
}

  getgraphsdata( ){
    this.services.getRequest(`Dashboard/GetAirFilterAnalytics?CustomerID=${this.customerid}`)
        .subscribe((response) => {
          
          if (response.statusCode === 200) {
            this.airfilters= response.data
          }
          else {
            this.airfilters= []

            // this.global._show_loader = false;
            this.global.showNotification('Error', '', 'No Record exist');
          }
        }, (error) => {
          this.global.showNotification('Error', '', 'Something Went Wrong');
          // this.global._show_loader = false;
        });

  }

  loadChartsdata() {
    
    let datetosend: any;
    this.dashboardVar.previousChartsdataDate == undefined ? 
    datetosend= this.dashboardVar.previousChartsdataDateOnInit : datetosend=this.dashboardVar.previousChartsdataDate,
    datetosend= this.formatDate(datetosend)
    if (!this.selectedmacid){
      this.global.showNotification("info", "", "Please select etrapp first.");
      this.dashboardVar.chartselectedvalue = this.itemOptionsOrders[0];
    }
    else{
      this.global._show_loader = true;
      
    this.services
      .getRequest(`Dashboard/GetCosmosDataByEtrappUnitIdAndDate?macId=${this.selectedmacid}&dateFrom=${datetosend}`)
      .subscribe(
        (response) => {
          if(response.statusCode==200){
            
            let obj1= response.data.rh;
            let obj2= response.data.tempF
            let obj3= response.data.bmp
            let obj4= response.data.acRunning
            obj4.data = obj4.data.map(subarray => [subarray[0], subarray[1] * 70]);
            let obj5= response.data.watPct
            obj4.step=true
            
            const series= [ {...obj2},{...obj1},{...obj3}]
            const acrunning=[{...obj4},{...obj5}]
            this.createChart(series, acrunning); 
          }
           
        },
        (error) => {
          this.global.HideLoader("#cartAlerts");
        }
      );
    }
  }

  createChart(series, acrunning) {
    
    const colors = ['#ff7270','#7CB5EC', '#90ED7D']; 
    series = series.map((s, index) => {
        return {
            ...s,
            color: colors[index % colors.length] 
        };
    });

    const colorac = ['#7CB5EC','#1976D2']; 
    acrunning = acrunning.map((s, index) => {
        return {
            ...s,
            color: colorac[index % colorac.length] 
        };
    }); 
    
  
    this.stock= new StockChart({

      rangeSelector: {
        buttons: [{
            type: 'hour',
            count: 1,
            text: '1h'
        }, {
            type: 'day',
            text: '1d'
        },{
            type: 'all',
            text: 'All'
        }], 
        selected: 2 
    },
    

        yAxis: {
            labels: {
                format: '{#if (gt value 0)}{/if}{value}'
            },
            plotLines: [{
                value: 0,
                width: 2,
                color: 'silver'
            }]
        },

        plotOptions: {
            series: {
                
                showInNavigator: true,
                dataGrouping: {
                  enabled: false 
              }
            }
        },

        tooltip: {
            pointFormat: '<span style="color:{series.color}">' +
                '{series.name}</span>: <b>{point.y}</b> ' +
                '{point.change}<br/>',
            valueDecimals: 2,
            split: true
        },

        series: series,
        legend: {
          enabled: true,
          layout: 'horizontal',
          align: 'center',
          verticalAlign: 'top',
          borderWidth: 1
      }
    });

    this.stock1= new StockChart({

      rangeSelector: {
        buttons: [{
            type: 'hour',
            count: 1,
            text: '1h'
        }, {
            type: 'day',
            text: '1d'
        },{
            type: 'all',
            text: 'All'
        }], 
        selected: 0 
    },
    

        yAxis: {
            labels: {
                format: '{#if (gt value 0)}{/if}{value}'
            },
            
            plotLines: [{
                value: 0,
                width: 2,
                color: 'silver'
            }]
        },

        plotOptions: {
            series: {
                
                showInNavigator: true,
                dataGrouping: {
                  enabled: false 
              }
            }
        },

        tooltip: {
            pointFormat: '<span style="color:{series.color}">' +
                '{series.name}</span>: <b>{point.y}</b> ' +
                '{point.change}<br/>',
            valueDecimals: 2,
            split: true
        },

        series: acrunning,
        legend: {
          enabled: true,
          layout: 'horizontal',
          align: 'center',
          verticalAlign: 'top',
          borderWidth: 1
      }
    });
    this.global._show_loader = false;

}


  
  formatDate(date: Date): string {
    return this.datePipe.transform(date, "yyyy-MM-dd'T'HH:mm:ss'Z'", 'UTC');
  }

  onChangeOrderBy(item, device: number) {
    let datelength = new Date().toISOString().length;
    let date = new Date().toISOString().substring(0, datelength);
    this.getTimeDifference(date, item.value, device);
    if (device == 1) {
      this.dashboardVar.serviceselectedvalue = item; 
      this.loadservices();
    } else if (device == 2) {
      this.dashboardVar.chartselectedvalue = item;
      this.loadChartsdata();

    }
  }

  loadservices() {
    const obj = {
      customerId:  this.customerid,
      id: this.global.getProObj().id,
      startFrom: 0,
      endTo: 0,
      startDate:
        this.dashboardVar.previousserviceDate == undefined
          ? this.dashboardVar.previousserviceDateOnInit
          : this.dashboardVar.previousserviceDate,
      endDate: new Date().toISOString(),
      address:this.address
    };
    // this.spinner.show()
    this.services
      .postRequest(`Dashboard/GetDashboardEtrappAlertsByCustomerAndProId`, obj)
      .subscribe(
        (response) => {
          if (response.statusCode == "200") {
            this.serviceslist=response.data
            // this.spinner.hide()
         
          } else if (response.statusCode == "409") {
            this.serviceslist=[]
          } else {
            this.global.HideLoader("#eTrappAlerts");
          }
        },
        (error) => {
          this.global.HideLoader("#eTrappAlerts");
        }
      );
  }

  getTimeDifference(date: any, obj: any, dev: number) {
    let date1: Date = date;
    switch (obj) {
      case "1day":
        if (dev == 1) {
          this.dashboardVar.previousserviceDate = this.previousDateforfilter(date1, 24);
        }  else{
          this.dashboardVar.previousChartsdataDate = this.previousDateforfilter(date1, 24);
        }
        break;
      case "7days":
        if (dev == 1) {
          this.dashboardVar.previousserviceDate = this.previousDateforfilter(date1, 168);
        } else{
          this.dashboardVar.previousChartsdataDate = this.previousDateforfilter(date1, 168);
        }
        break;
      case "30days":
        if (dev == 1) {
          this.dashboardVar.previousserviceDate = this.previousDateforfilter(date1, 720);
        } else{
          this.dashboardVar.previousChartsdataDate = this.previousDateforfilter(date1, 720);
        }
        break;
    }
  }
  
  closeModel() {
    this.modalRefChild.hide();
    this.modalRefChild = undefined;
    this.selectedmacid= null;
    this.stock.destroy()
    this.stock1.destroy()
    this.stock= null
    this.stock1= null
  }
  emitFunction() {
    this.test.emit('Emit counter');
    this.closeModel();
  }
  etrappWarranty(arr: any) {
    arr.map((element: any) => {
      element.timeDifference = this.previousDate(element.eInstalledOn, 8760)
    });
  }
  cartLife(arr: any) {
    arr.map((element: any) => {
      element.cartLife = this.calculateCartridgeLife(element.eInstalledOn)
    });
  }

  previousDateforfilter(date: any, hours: number) {
    ;
    date = new Date(date);
    let numberOfMiliseconds = date.getTime();
    let addMlSeconds = hours * 60 * 60 * 1000;
    let newDate = new Date(numberOfMiliseconds - addMlSeconds);
    return newDate;
  }

  previousDate(date: Date, hours: number) {
    let newDateS = new Date(date);
    let numberOfMiliseconds = newDateS.getTime();
    let addMlSeconds = (hours * 60) * 60 * 1000;
    let newDate = new Date(numberOfMiliseconds + addMlSeconds);
    let dateDifference = newDate.getTime() - numberOfMiliseconds;
    let totalDays = Math.ceil(dateDifference / (1000 * 3600 * 24));
    let todayDate = new Date();
    let differenceInTime = todayDate.getTime() - numberOfMiliseconds;
    let finalDays = Math.ceil(differenceInTime / (1000 * 3600 * 24))
    let differenceDays = totalDays - finalDays;
    return differenceDays;
  }
  calculateCartridgeLife(date: Date) {
    let installedOn: any = new Date(date);
    let todayDate: any = new Date();
    let diffTime = Math.abs(installedOn - todayDate);
    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    let cartLife = 180 - diffDays;
    return cartLife
  }
  getallcomments(){
    this.services.getRequest(`ProComment/GetCommentByCustomerId?customerId=${this.customerid}`).subscribe((response) => {

      if (response.statusCode === 200) {
        this.comments = response.data;
      }
      else{
        this.comments=[]
      }
    }, (error) => {
      console.log(error);
    })

  }
 

  getetrappdetails(etappid){
    this.services.getRequest(`EtrappUnit/GetEtrappUnitByConsumerIdAndEtrappId?consumerId=${this.customerid}&etrappUnitId=${etappid}`)
    .subscribe((response) => {
      if (response.statusCode == '200') {
        

        this.etrappdetails = response.data[0];
        if (this.etrappdetails.remainingCartridgeDays<=0){
          this.etrappdetails.remainingCartridgeDays=0
          this.zerocatridgedays=true
        }
        else{
          this.zerocatridgedays=false
        }
        if (this.etrappdetails.remainingCartridgeLife<=0){
          this.etrappdetails.remainingCartridgeLife=0
        }
        if (this.etrappdetails.battery<=0){
          this.etrappdetails.battery=0
        }
        if (this.etrappdetails.battery>=100){
          this.etrappdetails.battery=100
        }
        this.modalRefChild = this.modalService.show(this.templateRef, this.config);
        this.global._show_loader = false;
      }
      else{
        this.etrappdetails = [];
      }
    }, (error) => {
      console.log(error);
    })

  }
  getWaterLevelImage(waterLevel): string {

    if (waterLevel <= 0) {
      return '../../../../../assets/img/etrapp_0.png';
    } else if (waterLevel > 0 && waterLevel <= 25) {
      return '../../../../../assets/img/etrapp_25.png';
    } else if (waterLevel > 25 && waterLevel <= 50) {
      return '../../../../../assets/img/etrapp_50.png';
    } else if (waterLevel > 50 && waterLevel <= 75) {
      return '../../../../../assets/img/etrapp_75.png';
    } else if (waterLevel > 75 || waterLevel >= 100) {
      return '../../../../../assets/img/etrapp_100.png';
    } else {
      return ''; 
    }
  }
  getbatteryImage(level): string {

    if (level === 0) {
      return '../../../../../assets/img/battery_0.png';
    } else if (level > 0 && level <= 25) {
      return '../../../../../assets/img/battery_25.png';
    } else if (level > 25 && level <= 50) {
      return '../../../../../assets/img/battery_50.png';
    } else if (level > 50 && level <= 75) {
      return '../../../../../assets/img/battery_75.png';
    } else if (level > 75 || level >= 100) {
      return '../../../../../assets/img/battery_100.png';
    } else {
      return '../../../../../assets/img/battery_0.png'; 
    }
  }
  getWiFiImage(isOffline,wiFiQuality): string {
    if (isOffline === 1) {
      return '../../../../../assets/img/offline.png';
    } else if (isOffline === 0) {
      if (wiFiQuality === 0) {
        return '../../../../../assets/img/wifi_0.png';
      } else if (wiFiQuality === 33) {
        return '../../../../../assets/img/wifi_33.png';
      } else if (wiFiQuality === 66) {
        return '../../../../../assets/img/wifi_66.png';
      } else if (wiFiQuality === 100) {
        return '../../../../../assets/img/wifi_full.png';
      }
    }
    return ''; 
  }
  remainingFilterpercent:any;
  zerodays: boolean= false;
  zerocatridgedays: boolean=false
  calculateRemainingfilterDays(date, totaldays): number {
    const currentDate = new Date();
    const futureDate = new Date(date);
    const diffInMilliseconds =currentDate.getTime() - futureDate.getTime() ;
    const diffInDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24));
    let remainingDays = totaldays - diffInDays;
    if (remainingDays<0){
      remainingDays=0
      this.zerodays=true
    }
    else{
      this.zerodays=false
    }
    this.remainingFilterpercent= Math.round((remainingDays/totaldays)*100)
    return remainingDays
  }

  appointmentlist:any=[]

  getallappoitmnets(){
    this.services
      .getRequest(
        `SupportTicket/GetAppointmentsByConsumerId?consumerId=${this.customerid}&proId=${this.global.getProObj().id}`)
      .subscribe(
        (response) => {
         if(response.statusCode===200){
          this.appointmentlist=response.data
          // this.global._show_loader = false;
 
         }
         else{
          this.appointmentlist=[]
          // this.global._show_loader = false;

         }
        },
        (error) => {
          this.global.HideLoader("#mapsDiv");
        }
      );
  }
  fullScreenClick() {
    this.isFullScreen = !this.isFullScreen;
    if (this.isFullScreen) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  }
  fullScreenClickWidget(el: HTMLElement, isMap?) {
    this.isFullScreen = !this.isFullScreen;
    if (this.isFullScreen) {
      el.requestFullscreen();
      if (isMap !== undefined) {
        ;

        // this.dashboardVar.alertLvlObj = {
        //   isHigh: this.dashboardVar.alertLvlObj.isHigh,
        //   isMedium: this.dashboardVar.alertLvlObj.isMedium,
        //   isLow: this.dashboardVar.alertLvlObj.isLow,
        // };
      }
    } else {
      if (isMap !== undefined) {
        // this.dashboardVar.alertLvlObj = {
        //   isHigh: this.dashboardVar.alertLvlObj.isHigh,
        //   isMedium: this.dashboardVar.alertLvlObj.isMedium,
        //   isLow: this.dashboardVar.alertLvlObj.isLow,
        // };
      }
      // this.dashboardVar.markers.forEach((obj) => {
      //   obj.setVisible(true);
      // });
      document.exitFullscreen();
    }
  }
}
