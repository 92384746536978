<footer class="page-footer">
  <div class="footer-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-sm-6">
          <p class="mb-0 text-muted"></p>
        </div>
        <!-- <div class="col-sm-6 d-none d-sm-block">
          <ul class="breadcrumb pt-0 pr-0 float-right">
            <li class="breadcrumb-item mb-0">
              <a href="javascript:;" class="btn-link">Review</a>
            </li>
            <li class="breadcrumb-item mb-0">
              <a href="javascript:;" class="btn-link">Purchase</a>
            </li>
            <li class="breadcrumb-item mb-0">
              <a href="javascript:;" class="btn-link">Docs</a>
            </li>
          </ul>
        </div> -->
      </div>
    </div>
  </div>
</footer>
