import { Component, OnInit, Renderer2, AfterViewInit } from '@angular/core';
import { Injectable } from '@angular/core';
import { LangService } from 'src/app/shared/lang.service';
import { GlobalService } from 'src/app/shared/services/global.service';
import { environment } from 'src/environments/environment';
import *  as  data from '../../../assets/settings.json';
import { ThemeVariables, ThemeRef, lyl, StyleRenderer } from '@alyle/ui';
import { DatePipe } from '@angular/common';

const STYLES = (theme: ThemeVariables, ref: ThemeRef) => {
  const __ = ref.selectorsOf(STYLES);
  return {
    $global: lyl `{
      body {
        background-color: ${theme.background.default}
        color: ${theme.text.default}
        font-family: ${theme.typography.fontFamily}
        margin: 0
        direction: ${theme.direction}
      }
    }`,
    root: lyl `{
      display: block
    }`
  };
};
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  providers: [StyleRenderer, DatePipe]
})

@Injectable()
export class AppComponent implements OnInit, AfterViewInit {
  readonly classes = this.sRenderer.renderSheet(STYLES, true);

  isMultiColorActive = environment.isMultiColorActive;
  constructor(readonly sRenderer: StyleRenderer,
              private langService: LangService,
              private renderer: Renderer2,
              private global: GlobalService) {

  }

  ngOnInit() {
    this.getScaleTypes();
    this.langService.init();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.renderer.addClass(document.body, 'show');
    }, 1000);
    setTimeout(() => {
      this.renderer.addClass(document.body, 'default-transition');
    }, 1500);
  }
  getScaleTypes() {
    this.global.settings = data['default'];
    let reseller = localStorage.getItem("RESELLER");
    if (reseller === null || reseller === undefined) {
      this.global.name = undefined;
    }
    else {
      this.global.name = reseller
    }
  }
}
