import { LoginModule } from './../login/login.module';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, SessionGuard } from 'src/app/shared/guards';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('../layout/layout.module').then((m) => m.LayoutModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'tutorials',
    loadChildren: () =>
      import('../video-tutorials/video-tutorials.module').then((m) => m.VideoTutorialsModule),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('../login/login.module').then((m) => m.LoginModule),
    canActivate: [SessionGuard],

  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
