import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { FooterComponent } from './footer/footer.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TopnavComponent } from './topnav/topnav.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ColorSwitcherComponent } from '../color-switcher/color-switcher.component';
import { HeadingComponent } from './heading/heading.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardModule } from 'src/app/layout/dashboard/dashboard.module';


@NgModule({
  declarations: [
    FooterComponent,
    SidebarComponent,
    TopnavComponent,
    ColorSwitcherComponent,
    HeadingComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    CommonModule,
    TranslateModule.forChild(),
    CollapseModule,
    PerfectScrollbarModule,
    DashboardModule,
    BsDropdownModule.forRoot(),
  ],
  exports: [
    PerfectScrollbarModule,
    RouterModule,
    FooterComponent,
    SidebarComponent,
    TopnavComponent,
    ColorSwitcherComponent,
    TranslateModule,
    CommonModule,
    HeadingComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})
export class SharedModule { }
