import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeDifference'
})
export class TimeDifferencePipe implements PipeTransform {
  transform(alertTime: Date | string | number): string {
    const currentTime = new Date();
    const inputTime = new Date(alertTime);

    if (isNaN(inputTime.getTime())) {
      return 'Invalid date';
    }

    const timeDifference = Math.abs(currentTime.getTime() - inputTime.getTime());
    
    const totalHours = Math.floor(timeDifference / (1000 * 60 * 60));
    const days = Math.floor(totalHours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);
    const calculatedMonths = months - (years * 12);
    
    const hours = totalHours % 24;
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    if (years > 0) {
      return `${years} years ${calculatedMonths} months`;
    } else if (months > 0) {
      return `${months} months ${days % 30} days`;
    } else if (days > 0) {
      return `${days} days ${hours} hours and ${minutes} mins`;
    } else if (hours > 0) {
      return `${hours} hours ${minutes} mins and ${seconds} secs`;
    } else if (minutes > 0) {
      return `${minutes} mins and ${seconds} secs`;
    } else {
      return `${seconds} sec(s)`;
    }
  }
}

