import { Directive, Input, Renderer2, ElementRef, HostListener, Injectable } from '@angular/core';
import { Sort } from '../../utilities';

@Directive({
  selector: '[appSort]'
})
export class SortDirective {
  previousElem = undefined;
  @Input() appSort: Array<any>;


  constructor(private renderer: Renderer2, private targetElement: ElementRef) { }

  @HostListener("click")
  sortData() {
    const sort = new Sort();
    const elem = this.targetElement.nativeElement;
    const order = elem.getAttribute("data-order");
    const type = elem.getAttribute("data-type");
    const property = elem.getAttribute("data-name");
    if (order === "desc") {
      this.appSort.sort(sort.startSort(property, order, type));
      elem.setAttribute("data-order", "asc");
      elem.childNodes[1].className = "fa fa-sort-asc"
            elem.offsetParent.childNodes[0].childNodes[0].childNodes.forEach((x)=>{
              if(x.childNodes[0].textContent.trim() != elem.innerText.trim()){
                if(x.childNodes.length>1){
                  x.childNodes[1].className = "fa fa-sort"
                }
              }
              else{
                x.childNodes[1].className = "fa fa-sort-asc"

              }
            })

    } else {
      this.appSort.sort(sort.startSort(property, order, type));
      elem.setAttribute("data-order", "desc");
      elem.childNodes[1].className = "fa fa-sort-desc";
      elem.offsetParent.childNodes[0].childNodes[0].childNodes.forEach((x)=>{
        if(x.childNodes[0].textContent.trim() != elem.innerText.trim()){
          if(x.childNodes.length>1){
            x.childNodes[1].className = "fa fa-sort"
          }
        }
        else{
          x.childNodes[1].className = "fa fa-sort-desc"

        }
      })
    }
  }
}
